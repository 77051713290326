import React from 'react';
import './style.css';

function Slips() {
    return(
        <div className='slips-wrapper container-fluid'>
            <div className='title'>
                <h4 className='section-header'>WINTER SLIPS AND FALLS</h4>
                <p className='section-p'>Winter slips and falls are a leading cause of work injuries, and one of the most common injuries overall.</p>
            </div>
            <div className='row'>
                <div className='col-12 col-md-6 col-lg-4'>
                    <h1>1,000,000</h1>
                    <h5 className='section-subtitle'>SLIPS AND FALLS ANNUALLY</h5>
                    <p className='section-p'>According to the CDC, approximately 1 million Americans are injured annually as the result of falling on ice and snow</p>
                </div>
                <div className='col-12 col-md-6 col-lg-4'>
                    <h1>17,000</h1>
                    <h5 className='section-subtitle'>ANNUAL FATALITIES</h5>
                    <p className='section-p'>Slips, trips, and falls on ice and snow pose a serious risk. Tragically, these injuries are fatal for about 17,000 people every year.</p>
                </div>
                <div className='col-12 col-md-6 col-lg-4'>
                    <h1>42,480</h1>
                    <h5 className='section-subtitle'>INJURIES REQUIRING TIME OFF</h5>
                    <p className='section-p'>In the US in 2014, there were 42,480 workplace injuries and illnesses
                        involving ice, sleet, or snow that required at least one day away from work to
                        recuperate.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Slips;