import React from 'react';
import './style.css';

function About() {
    return(
        <div className='container-fluid about-wrapper'>
            <div className='row'>
                <div className='col-lg-6'>
                    <h1>FAST AND RELIABLE <br />SNOW SERVICES</h1>
                    {/* <a className='cta' href='/operate'>HOW WE OPERATE</a> */}
                </div>
                <div className='col-lg-6'>
                    <p className='section-p'>
                    At Transblue, we know that quality and cost are the driving forces behind choosing the right snow removal partner for your brand's locations. <br />Our comprehensive snow removal management plans will help with staying on budget, cost control initiatives, project planning and management, and environmental impacts that face your brand's image every day.
                    </p>
                    {/* <p className='section-p'>
                    Transblue is a leading provider of Snow and Ice services in Washington. Our Snow and Ice programs provide a holistic approach to parking lot and roadway maintenance and management. Knowing what you need to do and knowing when to do it is crucial for making the budgetary decisions that you are faced with when planning for your upcoming facility budgets. Transblue isn’t in the Snow and Ice business, we are in the Risk Removal business.   
                    </p> */}
                    {/* <p className='section-p pt-4'>It is easy to forget about the snow during the peak summer months, but this is when the best snow service provider contracts get booked! We recommend having a snow &amp; ice service provider completely booked by labor day. If you have have never booked a snow contract before or are looking to change providers, it’s easy to get started. At transblue we offer a “peace of mind” guarantee </p> */}
                    {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce ac felis posuere, ultricies odio fringilla, sollicitudin enim. Nam sollicitudin massa libero, id euismod massa lobortis eu. Fusce varius diam quis ex gravida, et luctus mauris facilisis. Morbi urna neque, gravida quis pellentesque vitae, aliquam sodales felis. Fusce varius diam quis ex gravida, et luctus mauris facilisis. Morbi urna neque, gravida quis pellentesque vitae, aliquam sodales felis. </p> */}
                </div>
            </div>
        </div>
    )
}

export default About;