import React, {Fragment} from 'react';
import './style.css';

function ServiceModal() {
    return(
        <Fragment>
                <button className='cta' data-toggle="modal" data-target="#serviceModal">
                    VIEW ALL SERVICES
                </button>
                
                <div className='service-modal-wrapper'>
                <div className="modal fade" id="serviceModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <h4 className='section-header'>WHAT WE DO</h4>
                            <ul>
                                <li>Snow Plowing</li>
                                <li>Deicing – liquid and granular</li>
                                <li>Snow Hauling</li>
                                <li>Sidewalk shoveling and deicing</li>
                                <li>Snow Melting</li>
                                <li>Truck Brush Services</li>
                                <li>Icicle Melting</li>
                                <li>Rooftop clearing</li>
                            </ul>
                        </div>      
                    </div>
                </div>
                </div>
        </Fragment>
    )
}

export default ServiceModal;