import React from 'react';
import csp from '../../../images/csp.png';
import sima from '../../../images/sima.jpg';
import './style.css';

function Certifications() {
    return(
        <div className='container-fluid certifications'>
            <div className='row'>
                <div className='col-lg-4 col-md-6 col-sm-12 my-auto pt-3 pr-0'>
                    <h2>PROTECTING CLIENTS NATIONWIDE</h2>
                    <p>Certifications You Can Count On</p>
                </div>
                <div className='col-lg-4 col-md-3 col-sm-6 col-6 my-auto text-center'>
                    <img src={csp} alt='Certified Snow Professional' />
                </div>
                <div className='col-lg-4 col-md-3 col-sm-6 col-6 my-auto text-center'>
                    <img src={sima} alt='Snow and Ice Management Association' />
                </div>
            </div>
        </div>
    )
}

export default Certifications;