import React, {Fragment} from 'react';
//import Fade from 'react-reveal/Fade';
import FranchiseNav from '../franchiseNav';
import './style.css';

function Header(props) {
    return (
        <Fragment>
            <FranchiseNav />
            <div className='header-wrapper' name={props.name}>
            {/* <img src={props.img} alt='Snow Plow' /> */}
            <div className='overlay'></div>
            <div className='header-text'>
                {props.title}
                {/* <Fade bottom cascade></Fade> */}
                {props.subtitle !== '' && 
                <div className='header-subtext'>{props.subtitle}</div>}
            </div>
        </div>
        </Fragment>
    )
}

export default Header;