import React from 'react';
import starbucks from '../../../images/starbucks.jpg';
import './style.css';

function Expertise() {
    return(
        <div className='container-fluid expertise'>
            <div className='row'>
                <div className='col-lg-6 order-lg-12'>
                    <img src={starbucks} alt='Starbucks Snow Plowing' />
                </div>
                <div className='col-lg-6 order-lg-12 left-text'>
                    <h4 className='section-header'>NATIONWIDE SERVICES, LOCAL EXPERTISE</h4>
                    <hr className='section-hr'></hr>
                    <p className='section-p'>Transblue is focused on building real business solutions with real time 
                        results. Our clients are able to focus on meeting strategic business 
                        objectives, while maximizing internal resources and efficiencies.</p>
                    <p className='section-p pb-4'> Using our unique consultative 
                        approach and latest technologies, Transblue works with clients to 
                        develop snow removal plans that include operational and financial 
                        efficiencies. We present on demand plowing services as an investment, 
                        rather than an expense, to help design a more effective approach to 
                        risk management and service execution.</p>
                </div>

            </div>
        </div>
    )
}

export default Expertise;