import React, {useState} from 'react';
import { useEasybase } from 'easybase-react';
import Captcha from '../captcha';
import './style.css';

import{ init } from 'emailjs-com';
import * as emailjs from 'emailjs-com'
init("user_iLZ3jXyTzXi5zQFlgf5DG");


function Form(props) {
    const { db } = useEasybase();
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [message, setMessage] = useState('')
    const [isSubmitted, setIsSubmitted] = useState(false)
    const [submitEnabled, setSubmitEnabled] = useState(false)

    function handleSubmit(e) {
        e.preventDefault();

        //SEND EMAIL TO INCOMING LEADS USING EMAILJS
        let templateParams = {
            website: 'SNOW WEBSITE',
            from_name: `${firstName} ${lastName}`,
            to_email: 'incomingleads@transblue.org', //CHANGE THIS TO INCOMINGLEADS@TRANSBLUE.ORG
            reply_to: email,
            phone: phone,
            message: message
           }

        emailjs.send(
        'service_gekurtf',
        'template_dqy1grk', //CONTACT TEMPLATE
            templateParams,
            process.env.REACT_APP_REACTJS_USER
        )

        let dbData = {
            website: 'SNOW WEBSITE',
            name: `${firstName} ${lastName}`,
            email: email,
            phone: phone,
            message: message
        }
        db('CONTACT').insert(dbData).one() 
    }

    return(
        <div className='container'>
            <button onClick={props.onClick} type="button" className="btn-close" aria-label="Close">x</button>
            <h6>SEND US A MESSAGE</h6>
        <form onSubmit={handleSubmit}>
            <div className='row mx-0'>
                <div className='col-6 pl-0 pr-2'>
                    <input id='firstName' onChange={(e) => setFirstName(e.target.value)} placeholder='First Name' value={firstName} required></input>
                </div>
                <div className='col-6 px-0'>
                    <input id='lastName' onChange={(e) => setLastName(e.target.value)} placeholder='Last Name' value={lastName} required></input>
                </div>
            </div>
            <div className='row mt-2'>
                <div className='col-12'>
                    <input id='phone' onChange={(e) => setPhone(e.target.value)} placeholder='Phone' value={phone} required></input>
                </div>
            </div>
            <div className='row mt-2'>
                <div className='col-12'>
                    <input id='email' onChange={(e) => setEmail(e.target.value)} placeholder='Email' value={email} required></input>
                </div>
            </div>
            <div className='row mt-2'>
                <div className='col-12'>
                    <textarea id='message' onChange={(e) => setMessage(e.target.value)} placeholder='Tell us about your project' value={message} required />
                </div>
            </div>
            <div className='row mt-2'>
                <div className='col-12'>
                    <button className={!submitEnabled ? 'disabled' : ''} disabled={!submitEnabled} type='submit' id='send' onClick={() => setIsSubmitted(true)}>SUBMIT</button>
                    {!isSubmitted && <Captcha onChange={() => setSubmitEnabled(true)} />}
                </div>
            </div>
            {isSubmitted && <div className='row submitted'>
                <div className='col-12'>
                Thank you! We will contact you shortly
                </div>
            </div>}
        </form>
    </div>
    )
}

export default Form;