import React, { Fragment, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import Header from '../components/header';
import Navbar from '../components/navbar';
import ContactCta from '../components/contactCta';
import About from '../components/home/about';
import Services from '../components/home/services';
import Quality from '../components/home/quality';
import Accessibility from '../components/home/accessibility';
import Banner from '../components/home/banner';
import Slips from '../components/home/slips';
import Businesses from '../components/home/businesses';
import Expertise from '../components/home/expertise';
import Certifications from '../components/home/certifications';
import Brands from '../components/home/brands';
import Footer from '../components/footer';
import Equipment from '../components/home/equipment';
import header from '../images/headers/home.jpeg';
import headerSmall from '../images/headers/homeSmall.jpg';

import ReactGA from 'react-ga';
import CaseStudy from '../components/caseStudy/caseStudy';
import CaseStudyModal from '../components/caseStudyModal/caseStudyModal';
const TRACKING_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_ID // YOUR_OWN_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

function Home() {

    useEffect(() => {
        ReactGA.event({
            category: 'User',
            action: 'Visited Snow Landing Page'
          });
    }, [])
    return(
        <Fragment>
            <CaseStudyModal />
            <Helmet>
                <title>Transblue Snow Services Home</title>
                <meta name="description" content="Transblue Snow Services Home" />
            </Helmet>
            <Header img={header} imgSmall={headerSmall} title='SNOW SERVICES' subtitle='' name='home' />
            <Navbar page='HOME' parentPage='snow' />
            <ContactCta />
            <div className='wrapper'>
                <About />
            </div>
            <CaseStudy />
            <div className='wrapper'>
                <Expertise />
                <Slips />
            </div>
            <Banner />
            <div className='wrapper'>
                <Accessibility />
            </div>
            <Quality />
            <div className='wrapper'>
            <Services />
            <Businesses />
            <Certifications />
            </div>
            
            <Equipment />
            <Brands />
            <Footer />
        </Fragment>
    )
}

export default Home;