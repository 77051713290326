import React from 'react';
import Fade from 'react-reveal/Fade';
//import commercial from '../../../images/home/commercial2.jpg';
import business from '../../../images/home/businesses.jpeg';
import businessSmall from '../../../images/home/businessesSmall.jpg';
import ContactModal from '../../contactModal';

import './style.css';

function Equipment() {
    return(
        <div className='equipment-wrapper'>
            {/* <img src={businessSmall} alt='' /> */}
            <div className='overlay'></div>
            <div className='text'>
                <h4>57,000 Businesses Nationwide Serviced by Transblue</h4>
                <Fade bottom>
                    <p className='section-p'>We have been delivering value for some of the finest Fortune 500 companies in the world for nearly 20 years. We guarantee World Class Professionalism at every step of the way</p>
                {/* <ul>
                    <li>National Weather Monitoring</li>
                    <li>World Class Professionalism</li>
                    <li>Unrivaled Portfolio</li>
                </ul> */}
                </Fade>
                <ContactModal />
            </div>
        </div>
    )
}

export default Equipment;