import React, {lazy, Suspense} from 'react';

import {BrowserRouter as Router, Route} from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import Home from './hero/home.js';

const FAQ = lazy(() => import('./hero/faq.js'));
const HowWeOperate = lazy(() => import('./hero/howWeOperate.js'));
const Pricing = lazy(() => import('./hero/pricing.js'));
const Contact = lazy(() => import('./hero/contact/index.js'));
const Emergency = lazy(() => import('./hero/emergency'));
const Gallery = lazy(() => import('./hero/gallery'));
const Software = lazy(() => import('./hero/software'));

function App() {
  return (
    <HelmetProvider>
      <Router>
        <Suspense fallback={<div>Loading...</div>} >
          <Route exact path='/' component={Home} />
          <Route exact path='/operate' component={HowWeOperate} />
          <Route exact path='/faq' component={FAQ} />
          <Route exact path='/pricing' component={Pricing} />
          <Route exact path='/contact' component={Contact} />
          <Route exact path='/emergency' component={Emergency} />
          <Route exact path='/gallery' component={Gallery} />
          <Route exact path='/software' component={Software} />
        </Suspense>
      </Router>
    </HelmetProvider>
  );
}

export default App;
