import React, { Component } from "react";
import { Link } from "react-router-dom";
import logo from "../../images/logo.png";
import "./style.css";

class Navbar extends Component {
  state = {
    bgColor: "transparent",
    logo: true,
    topPadding: "topPadding",
    // scrollHeight: 300
  };

  componentDidMount() {
    if (this.props.page === "FAQ") this.setState({ bgColor: "black" });
    document.addEventListener("scroll", () => {
      let bgColor =
        window.scrollY > window.innerHeight / 2 ? "black" : "transparent";
      let displayPage = window.scrollY > 300 ? true : false;
      let topPadding = window.scrollY > 30 ? "" : "topPadding";
      this.setState({
        bgColor: bgColor,
        displayPage: displayPage,
        topPadding: topPadding,
      });
    });
  }

  toggleSmallScreen = () => {
    if (
      this.state.bgColor === "transparent" &&
      window.scrollY < window.innerHeight
    )
      this.setState({ bgColor: "black" });
    else if (
      this.state.bgColor === "black" &&
      window.scrollY < window.innerWidth / 3 &&
      this.props.page !== "FAQ"
    )
      this.setState({ bgColor: "transparent" });
  };

  render() {
    return (
      <nav
        className={`navbar navbar-expand-xl bg-dark navbar-dark fixed-top ${this.state.bgColor} ${this.props.page} ${this.state.topPadding} ml-auto`}
      >
        {this.state.logo && (
          <a className="navbar-brand" href="http://transblue.com/">
            <img src={logo} id="logo" alt="Transblue Logo"></img>
          </a>
        )}
        {this.state.displayPage && (
          <div className="nav-page">{this.props.page}</div>
        )}

        <button
          onClick={this.toggleSmallScreen}
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav ml-auto">
            <li className="nav-item ml-auto">
              <a className="nav-link" href="http://transblue.com/">
                HOME
              </a>
            </li>
            <li className="nav-item dropdown ml-auto">
              <button
                className="nav-link dropdown-toggle ml-auto"
                id="navbarDropdown"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                SERVICES
              </button>
              <div
                className={`dropdown-menu ${this.state.bgColor}`}
                aria-labelledby="navbarDropdown"
              >
                <a
                  className="dropdown-item"
                  href="https://2123.transblue.org/residential"
                >
                  RESIDENTIAL
                </a>
                <a
                  className="dropdown-item"
                  href="https://2123.transblue.org/multifamily"
                >
                  MULTIFAMILY
                </a>
                <a
                  className="dropdown-item"
                  href="http://2123.transblue.org/commercial"
                >
                  COMMERCIAL
                </a>
                <a
                  className="dropdown-item"
                  href="http://2123.transblue.org/government"
                >
                  GOVERNMENT
                </a>
              </div>
            </li>
            <li className="nav-item dropdown ml-auto">
              <button
                className="nav-link dropdown-toggle ml-auto"
                id="navbarDropdown"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                GREEN SOLUTIONS
              </button>
              <div
                className={`dropdown-menu ${this.state.bgColor}`}
                aria-labelledby="navbarDropdown"
              >
                <a
                  className="dropdown-item"
                  href="http://2123green.transblue.org/"
                >
                  ABOUT
                </a>
                <a
                  className="dropdown-item"
                  href="http://2123green.transblue.org/residential"
                >
                  RESIDENTIAL
                </a>
                <a
                  className="dropdown-item"
                  href="http://2123green.transblue.org/multifamily"
                >
                  MULTIFAMILY
                </a>
                <a
                  className="dropdown-item"
                  href="http://2123green.transblue.org/commercial"
                >
                  COMMERCIAL
                </a>
              </div>
            </li>
            <li className="nav-item dropdown ml-auto">
              <button
                className="nav-link dropdown-toggle ml-auto"
                id="navbarDropdown"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                SNOW REMOVAL
              </button>
              <div
                className={`dropdown-menu ${this.state.bgColor}`}
                aria-labelledby="navbarDropdown"
              >
                <a className="dropdown-item" href="/">
                  ABOUT
                </a>
                <a className="dropdown-item" href="/operate">
                  HOW WE OPERATE
                </a>
                <a className="dropdown-item" href="/emergency">
                  EMERGENCY & RISK
                </a>
                <a className="dropdown-item" href="/software">
                  SOFTWARE
                </a>
                <a className="dropdown-item" href="/gallery">
                  GALLERY
                </a>

                <a className="dropdown-item" href="/pricing">
                  PRICING
                </a>
                <a className="dropdown-item" href="/faq">
                  FAQ
                </a>
              </div>
            </li>
            <li className="nav-item ml-auto">
              <a className="nav-link" href="http://transblue.com/locations">
                LOCATIONS
              </a>
            </li>
            <li className="nav-item dropdown ml-auto">
              <button
                className="nav-link dropdown-toggle ml-auto"
                id="navbarDropdown"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                OTHER
              </button>
              <div
                className={`dropdown-menu ${this.state.bgColor}`}
                aria-labelledby="navbarDropdown"
              >
                <a
                  className="dropdown-item"
                  href="http://transblue.com/subcontractor"
                >
                  SUBCONTRACTORS
                </a>
                <a
                  className="dropdown-item"
                  href="http://transblue.com/finance"
                  target="_blank"
                  rel="noreferrer"
                >
                  GET FINANCED
                </a>
                {!this.props.franchise && (
                  <a className="dropdown-item" href="/contact">
                    CONTACT
                  </a>
                )}
                <a
                  className="dropdown-item"
                  href="http://transblue.com/featured"
                >
                  PROJECTS
                </a>
                <a className="dropdown-item" href="http://transblue.com/blog">
                  BLOG
                </a>
                <a
                  className="dropdown-item"
                  href="http://transblue.com/givesback"
                >
                  GIVING BACK
                </a>
              </div>
            </li>
          </ul>
        </div>
      </nav>
    );
  }
}

export default Navbar;
