import React from 'react';
import ContactModal from '../../contactModal';
import tim4 from '../../../images/tim4.jpg';

import './style.css';

function Accessibility() {
    return(
        <div className='container-fluid accessibility-wrapper'>
            <div className='row'>
            <div className='col-lg-6'>
                    <img className='pt-4' src={tim4} alt='Commercial Snow Plowing' />
                </div>
                <div className='col-lg-6 left-text'>
                    <h4 className='section-header'>INCREASE ACCESSIBILITY</h4>
                    <hr className='section-hr' />
                    <p className='mb-3 section-p'>Access to your business and safety for your customers and employees is necessary to keep doors open. Slips and falls in high traffic areas pose a risk not just to your customers and employees, but to your business.</p>
                    <p className='section-p'> Planning for the cold months provides peace of mind. Plan ahead with Transblue so when the winter sneaks up on you, you are not left waiting and paying more for snow hauling.</p>
                    <a className='cta' href='https://transblue.com/locations'>FIND A LOCATION</a>
                    <ContactModal />
                </div>

            </div>
        </div>
    )
}

export default Accessibility;