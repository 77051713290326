import React from 'react';
import ServiceModal from '../../serviceModal';
import plow from '../../../images/plow.jpg';
import deice from '../../../images/deice.jpg';
import hauling from '../../../images/hauling.jpg';
import './style.css';

function Services() {

    return(
        <div className='container-fluid services-wrapper'>
            <div className='row title'>
                <h4 className='section-header'>OUR SERVICES</h4>
                <p className='section-p'>Our team of experts is trained and ready to handle any type of snow storm that blows in this winter. We currently provide snow services to multi-site clients in over 40 snow and ice markets, with over 80 million Sq. ft. of parking lots within our portfolio.</p>
            </div>
            <div className='row'>
                <div className='col-lg-4'>
                    <img src={plow} alt='Snow Plowing' />
                    <p className='section-subheader'>SNOW PLOWING</p>
                    <p className='section-p'>Our crews will plow your parking lots and other open spaces, and they'll also shovel your sidewalks and walkways to increase the flow of foot traffic.</p>
                </div>
                <div className='col-lg-4'>
                    <img src={deice} alt='De-iced Pathway' />
                    <p className='section-subheader'>SALT &amp; DE-ICING</p>
                    <p className='section-p'>We can arrange the application of rock salt and other fast-acting deicers to clear your walkways and parking lots.  </p>
                </div>
                <div className='col-lg-4'>
                    <img src={hauling} alt='Snow Hauling' />
                    <p className='section-subheader'>SNOW HAULING</p>
                    <p className='section-p'>We provide snow hauling &amp; stacking that utilizes plow trucks and other heavy equipment to clear up your lot of unsightly piles and allow customers to park freely. </p>
                </div>
                <div className='service-modal'>
                    <ServiceModal />
                </div>
            </div>
        </div>
    )
}

export default Services;