import React from 'react';
import ContactModal from '../../contactModal';
import business from '../../../images/home/business.jpg';
import './style.css';

function Businesses() {
    return(
        <div className='business-wrapper container-fluid'>
            <div className='row'>
                <div className='col-12 col-lg-7'>
                    <img src={business} alt='' />
                </div>
                <div className='col-12 col-lg-5 left-text'>
                    <h4 className='section-header'>OUR SNOW &amp; ICE CLIENTS:</h4>
                    <p className='asterisk section-p'><span>*</span>Property management companies</p>
                    <p className='asterisk section-p'><span>*</span>Retail stores</p>
                    <p className='asterisk section-p'><span>*</span>Restaurants</p>
                    <p className='asterisk section-p'><span>*</span>Gas Stations</p>
                    <p className='asterisk section-p'><span>*</span>Malls</p>
                    <p className='asterisk section-p'><span>*</span>Big Box locations</p>
                    <p className='asterisk section-p'><span>*</span>Industrial parks &amp; business parks</p>
                    <p className='asterisk section-p'><span>*</span>Airports</p>
                    <p className='asterisk section-p'><span>*</span>Medical Facilities</p>
                    <p className='asterisk section-p'><span>*</span>Federal, state, and local government agencies. </p>
                    <p className='asterisk section-p'><span>*</span>Apartments, Condos, HOA’s</p>
                    <ContactModal />
                </div>
            </div>
        </div>
    )
}

export default Businesses;