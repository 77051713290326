import React from 'react';
import './style.css';

function Banner() {
    return(
        <div className='banner'>
            <h2>WE KEEP PROPERTIES SAFE</h2>
            <p>before, during &amp; after</p>
        </div>
    )
}

export default Banner;