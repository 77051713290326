import React from 'react';

import './style.css';

function Quality() {
    return(
        <div className='quality-wrapper'>
            <div className='text centered-text'>
            <h4>QUALITY WORK EVERY TIME</h4>
            <hr className='center-hr'/>
            {/* <p className='section-p'>Nam sollicitudin massa libero, id euismod massa lobortis eu. Fusce varius diam quis ex gravida, et luctus mauris facilisis. Morbi urna neque, gravida quis pellentesque vitae, aliquam sodales felis.</p> */}
            <p className='section-p'>20 years in the business of snow and ice control has given Transblue a broad understanding of the liabilities associated with the winter season. Our experience has also granted us superior efficiency when held up against our competition.</p>
            </div>
        </div>
    )
}

export default Quality;